<template>
    <div class="group-buy">
        <div class="group-buy-container" v-if="showPage">
            <van-list
                v-model="loading"
                @load="onLoad"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
            >

                <van-card
                    v-for="item in goods"
                    :key="item.id"
                    :price="item.price_pin"
                    :title="item.title"
                    :thumb="item.thumb_pic"
                    :desc="item.share_title"
                    :origin-price="item.price"
                    @click="clickDetail(item.id)"
                    :lazy-load="true"
                >
                    <template #bottom>
                        <div class="bottom">
                            <span>
                                <van-icon name="friends-o" size="17"/>{{ item.buy_num }}人成团
                            </span>
                            <van-button size="mini" color="#eb5f53">立即抢购</van-button>
                        </div>
                    </template>
                </van-card>

            </van-list>
        </div>
        <van-empty image="https://khome2.tuzuu.com/klfront/newlogo.png" description="正在加载..." v-else/>
        <tab-bar :active="0" />
    </div>
</template>

<script>
import TabBar from "@/components/tabbar";

export default {
    data: () => ({
        goods: [],
        loading: false,
        finished: false,
        over: false,
        page: 1,
        showPage: false,
    }),
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            if (!this.over) {
                const res = await axios.get("/goods_list", {
                    params:{
                        page: this.page,
                        cate_id: "9999",
                    }
                });
                
                if (res.code == 1) {
                    this.showPage = true;
                    if (res.data.goods.length === 0) {
                        this.over = true;
                        this.finished = true;
                        return;
                    }
                    this.loading = false;
                    this.page++;
                    this.goods.push(...res.data.goods);
                }
            }
            
        },
        clickDetail(id) {
            this.$router.push("/detail/" + id);
        },
        onLoad() {
            if (this.over) {
                this.finished = true;
                return;
            }
            this.getData();
        },
    },
    components: {
        TabBar,
    },
};
</script>

<style lang="less" scoped>
.group-buy {
    width: 100%;
    height: 100%;
    color: #333;

    .group-buy-container {
        padding-bottom: 70px;
        box-sizing: border-box;
    }
    .van-empty {
        height: 100%;
        /deep/.van-empty__description {
            color: rgb(142,205,88);
            font-size: 15px;
            margin: 0;
        }
    }
}
.van-card {
    background: none;
    padding: 10px 6px;
    margin: 0;

    .van-card__thumb {
        width: 50%;
        height: 100px;

        /deep/ img {
            border-radius: 0px;
            object-fit: fill !important;
        }
    }
    .van-card__title {
        color: #000;
        font-size: 15px;
    }
    .van-card__price {
        color: #eb5f53;
        font-weight: bold;
        font-size: 15px;
    }
    .van-card__desc {
        font-size: 13px;
    }
    .van-card__origin-price {
        font-size: 14px;
        color: #d2cbcb;
    }
    .van-card__origin-price {
        float: right;
    }
    .van-card__bottom .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .bottom .van-button {
        font-size: 12px;
    }
    .bottom > span {
        color: #c3b5b5;
        font-size: 12px;
        .van-icon {
            vertical-align: -3px;
        }
    }
    .van-card__price-integer {
        font-size: 15px;
    }
}
</style>
